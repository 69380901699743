<template>
  <b-modal
    id="processScoreModal"
    centered
    :title="editable ? `Nhập điểm quá trình lớp ${creditClass.subjectName} - ${creditClass.code}` : `Xem điểm quá trình lớp ${creditClass.subjectName} - ${creditClass.code}`"
    size="xl"
    body-class="position-static"
    no-close-on-backdrop
    @show="onShow"
    @hide="onHide"
  >
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :line-numbers="true"
        >
          <div slot="table-actions">
            <input
              ref="inputFileRef"
              class="input-file"
              type="file"
              @change="importExcel"
            >
            <b-button v-if="processScoreUpdatable" variant="success" class="ml-1 btn-sm" @click="open">
                  <span class="text-nowrap text-right">
                        <feather-icon icon="SendIcon" /> Upload file
                  </span>
            </b-button>

            <b-button variant="primary" class="ml-1 btn-sm" @click="downloadFile">
                  <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Tải file import
                  </span>
            </b-button>
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="GridIcon"
                  size="20"
                  class="text-body"
                />
              </template>
              <b-dropdown-item
                v-for="(column, index) in columns"
                :key="`${index}${column.hidden}`"
                @click.native.capture.stop="toggleColumn(index, $event)"
              >
                <b-form-checkbox :checked="!column.hidden">
                  {{ column.label }}
                </b-form-checkbox>
              </b-dropdown-item>
            </b-dropdown>

          </div>
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'gender'">
              {{ getGender(props.row.gender) }}
            </span>

            <template v-else-if="props.column.field.startsWith('score') && scoreQuantity > 0 && editable">
              <div
                v-for="n in scoreQuantity"
                :key="n"
              >
                <span v-if="props.column.field === `score${n}`">
                  <validation-provider
                    #default="{ errors }"
                    rules="required|between:0,10"
                    :name="props.column.label"
                  >
                    <b-form-input
                      v-model.number="props.row[`score${n}`]"
                      min="0"
                      max="10"
                      @change="changeCell(props.row[`score${n}`], props.column.field, props.row.originalIndex)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </span>
              </div>
            </template>
            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-if="processScoreUpdatable"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="onSave(dataSources)"
        >
          <span class="text-right">
            <feather-icon icon="CheckIcon" /> Lưu lại
          </span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="mr-1"
          @click="exportPdf"
        >
          <b-img
            :src="require('@/assets/images/icons/file-icons/pdf.png')"
            height="auto"
            width="12"
            class="mr-1"
          />
          <span class="align-items-center">Xuất PDF</span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$bvModal.hide('processScoreModal')"
        >
          <span class="text-right">
            <feather-icon icon="XIcon" /> Hủy
          </span>
        </b-button>
      </div>
    </template>
    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
      no-wrap
    />
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCol,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BFormInput,
  BModal, BDropdownItem, BDropdown, BFormCheckbox, BImg, BFormFile
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { GENDERS } from '@/const/status'
import { getUser } from '@/auth/utils'
import { ValidationProvider } from 'vee-validate'
import { between, required } from '@core/utils/validations/validations'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import {Workbook} from "@syncfusion/ej2-excel-export";
import * as XLSX from "xlsx";

export default {
  name: 'ProcessScore',

  directives: {
    Ripple,
  },
  components: {
    BImg,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    BButton,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormInput,
    BModal,
    BFormFile,
    VueGoodTable,
  },
  props: {
    creditClass: {
      type: Object,
      default: () => ({
        subjectName: '',
        code: '',
      }),
    },
    updatable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      scoreQuantity: 0,
      originScores: [],
      editable: false,
      required,
      between,
      dataTable: [],
      dataSources: [],
    }
  },
  computed: {
    ...mapGetters({
      processScores: 'creditClass/processScores',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    user() {
      return getUser()
    },
    // dataSources() {
    //   const data = []
    //   if (this.processScores.length === 0) return data
    //   this.processScores.forEach(processScore => {
    //     const { scores, ...rest } = processScore
    //     if (scores.length > 0) {
    //       this.scoreQuantity = scores.length
    //       let element = { ...rest }
    //       scores.forEach(score => {
    //         const sc = {}
    //         sc[`id${score.code}`] = score.id
    //         sc[score.code] = score.numberValue
    //         element = { ...element, ...sc }
    //       })
    //       data.push(element)
    //     }
    //   })
    //   return data
    // },
    columns() {
      let data = [
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã sinh viên',
          },
          sortable: true,
          hidden: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ',
          },
          sortable: true,
          hidden: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          sortable: false,
          hidden: false,
          thClass: 'text-center',
        },
      ]
      if (this.processScores.length > 0) {
        const dataSource = this.processScores[0]
        if (dataSource.scores.length > 0) {
          dataSource.scores.forEach(score => {
            data = [...data, {
              label: score.name,
              field: score.code,
              hidden: false,
              sortable: false,
              thClass: 'text-center',
              tdClass: 'text-center',
            }]
          })
        }
      }
      return data
    },
    processScoreUpdatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.PROCESS_SCORE) && this.editable
    },
  },
  methods: {
    ...mapMutations({
      setDataSources: 'creditClass/SET_PROCESS_SCORES',
    }),
    ...mapActions({
      getProcessScores: 'creditClass/getProcessScores',
      saveProcessScores: 'creditClass/saveProcessScores',
      exportPdfProcessScores: 'creditClass/exportPdfProcessScores',
    }),
    async onShow() {
      this.editable = this.updatable
      await this.getDataSourcesFromStore()
    },
    onHide() {
      this.setDataSources([])
      this.originScores = []
    },

    convertData(){
      const data = []
      if (this.processScores.length === 0) return data
      this.processScores.forEach(processScore => {
        const { scores, ...rest } = processScore
        if (scores.length > 0) {
          this.scoreQuantity = scores.length
          let element = { ...rest }
          scores.forEach(score => {
            const sc = {}
            sc[`id${score.code}`] = score.id
            sc[score.code] = score.numberValue
            element = { ...element, ...sc }
          })
          data.push(element)
        }
      })
      return data
    },

    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getProcessScores({ creditClassId: this.creditClass.id })
        this.dataTable = this.convertData()
        this.dataSources = this.convertData()
        this.originScores = this.dataSources.map(elm => ({ ...elm }))
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    getGender(id) {
      return GENDERS.find(gender => gender.value === id).label
    },
    toggleColumn(index, event) {
      this.$set(this.columns[index], 'hidden', !this.columns[index].hidden)
      this.$forceUpdate()
    },
    changeCell(data, column, row) {
      this.dataSources[row][column] = data
    },
    async onSave(data) {
      const valid = this.isValid(data)
      if (valid) {
        const scores = []
        const scoreCheckedColumns = this.columns
          .filter(column => column.field.startsWith('score') && !column.hidden)
          .map(column => column.field)
        scoreCheckedColumns.forEach(column => {
          const diffs = data.filter(o1 => this.originScores
            .some(o2 => o1[`id${column}`] === o2[`id${column}`] && o1[column] !== o2[column]))
          if (diffs.length > 0) {
            diffs.forEach(score => {
              scores.push({
                id: score[`id${column}`],
                numberValue: score[column],
              })
            })
          }
        })
        if (scores.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không có điểm nào thay đổi giá trị',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.isLoading = true
        try {
          const response = await this.saveProcessScores({ scores, creditClassId: this.creditClass.id })
          if (response) {
            const { isSuccessful, message, countEmptyScore } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (countEmptyScore === 0) {
                this.editable = false
              }
              await this.getDataSourcesFromStore()
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    isValid(data) {
      const scoreCheckedColumns = this.columns
        .filter(column => column.field.startsWith('score') && !column.hidden)
        .map(column => column.field)
      for (let i = 0; i < data.length; i += 1) {
        for (let n = 0; n < this.scoreQuantity; n += 1) {
          if (scoreCheckedColumns.includes(`score${n + 1}`)) {
            if (data[i][`score${n + 1}`] === null || data[i][`score${n + 1}`] === '') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Vui lòng nhập đầy đủ dữ liệu điểm',
                  icon: 'InfoIcon',
                  variant: 'warning',
                },
              })
              return false
            }
            if (isNaN(data[i][`score${n + 1}`])) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Vui lòng nhập đúng kiểu dữ liệu điểm',
                  icon: 'InfoIcon',
                  variant: 'warning',
                },
              })
              return false
            }
            if (data[i][`score${n + 1}`] < 0 || data[i][`score${n + 1}`] > 10) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Điểm chỉ được nằm trong khoảng từ 0 đến 10',
                  icon: 'InfoIcon',
                  variant: 'warning',
                },
              })
              return false
            }
          }
        }
      }
      return true
    },
    async exportPdf() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdfProcessScores({ creditClassId: this.creditClass.id })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    open(){
      this.$refs.inputFileRef.click()
    },

    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      this.readFile(files)
    },

    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
            !excellist[0].hasOwnProperty('MA_SINH_VIEN')
            || !excellist[0].hasOwnProperty('HO_DEM')
            || !excellist[0].hasOwnProperty('TEN')
          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }
          for (let i = 0; i < this.dataTable.length ; i++){
            this.dataTable[i] = {...this.dataTable[i], ...excellist[i]}
          }
          this.dataSources = this.dataTable
        } catch (e) {
          return alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
    },

    downloadFile() {
      let countScore = this.processScores ? this.processScores[0].scores.length : 0
      const countColumn = this.columns.filter(item => item.hidden === false).length
      const item = { index: countColumn, width: 100 }
      const arrColumn = this.columns.filter(item => item.hidden === false)

      const columns = []
      const cells = []
      let j = 1
      arrColumn.forEach((item, index) => {
        columns.push({
          index: index + 1,
          width: 150
        })
        let temp1 = {
          colSpan: 1,
          style: {
            borders: {
              color: '#00000000',
              lineStyle: 'thin',
            },
            fontColor: '#ffffff',
            backColor: '#000000',
          },
        }
        if (item.field == 'studentCode') {
          let tempA = {
            index: j,
            value: 'MA_SINH_VIEN',
          }
          let temp = { ...temp1, ...tempA }
          cells.push(temp)
          j++
        }
        if (item.field == 'lastName') {
          let tempA = {
            index: j,
            value: 'HO_DEM',
          }
          let temp = { ...temp1, ...tempA }
          cells.push(temp)
          j++

        }
        if (item.field == 'firstName') {
          let tempA = {
            index: j,
            value: 'TEN',
          }
          let temp = { ...temp1, ...tempA }
          cells.push(temp)
          j++
        }

        for( let k = 1; k <= countScore; k++) {
          if (item.field == `score${k}`) {
            let tempA = {
              index: j,
              value: item.field,
            }
            let temp = { ...temp1, ...tempA }
            cells.push(temp)
            j++
          }
        }
      })
      const rows = []
      const headerRow = {
        index: 1,
        cells: cells
      }
      rows.push(headerRow)
      this.processScores.forEach((item, index) => {
          let rowCell = []
          for(let i = 0; i < countColumn; i++) {
            if (arrColumn[i].field == 'studentCode') {
              let temp = {
                index: i + 1,
                value: item.studentCode,
              }
              rowCell.push(temp)
            }
            if (arrColumn[i].field == 'lastName') {
              let temp = {
                index: i + 1,
                value: item.lastName,
              }
              rowCell.push(temp)
            }
            if (arrColumn[i].field == 'firstName') {
              let temp = {
                index: i + 1,
                value: item.firstName,
              }
              rowCell.push(temp)
            }

            for( let k = 1; k <= countScore; k++) {
              if (arrColumn[i].field == `score${k}`) {
                let temp = {
                  index: i + 1,
                  value: item.scores?.find(temp => temp.code == `score${k}`)?.numberValue,
                }
                rowCell.push(temp)
              }
            }

            // if (arrColumn[i].field == 'score1') {
            //   let temp = {
            //     index: i + 1,
            //     value: item.scores?.find(temp => temp.code == 'score1')?.numberValue,
            //   }
            //   rowCell.push(temp)
            // }
            // if (arrColumn[i].field == 'score2') {
            //   let temp = {
            //     index: i + 1,
            //     value: item.scores?.find(temp => temp.code == 'score2')?.numberValue,
            //   }
            //   rowCell.push(temp)
            // }
            // if (arrColumn[i].field == 'score3') {
            //   let temp = {
            //     index: i + 1,
            //     value: item.scores?.find(temp => temp.code == 'score3')?.numberValue,
            //   }
            //   rowCell.push(temp)
            // }
          }
          let rowsNew = {
            index: index + 2,
            cells: rowCell
          }
          rows.push(rowsNew)
      })
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('import_diemsinhvien.xlsx')
    },


  },
}
</script>
